import { useState } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap'
import './Concept.scss'
import ReactPlayer from 'react-player';

const Concept = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const galleryAmenities = [
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-amenidades-queretaro-puntolivo-salones.jpg', caption: 'Salón de usos múltiples' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-amenidades-queretaro-puntolivo-amenidades-alberca.jpg', caption: 'Alberca' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-amenidades-queretaro-puntolivo-juegos-2.jpg', caption: 'Juegos' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-amenidades-queretaro-puntolivo-terraza-yoga.jpg', caption: 'Terraza Yoga' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-amenidades-alberca-interior.jpg', caption: 'Alberca interior' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-amenidades-gimnasio-2.jpg', caption: 'Gimnasio' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-amenidades-gimnasio.jpg', caption: 'Gimnasio' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-amenidades-juegos.jpg', caption: 'Juegos' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-amenidades-restaurante.jpg', caption: 'Restaurante' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-amenidades-terraza.jpg', caption: 'Terraza' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-casa-club.jpg', caption: 'Casa Club' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-galeria-amenidades-terraza-alberca.jpg', caption: 'Terraza Alberca' },
        { src: 'assets/images/concept/gallery-amenities/proyecto-residencial-lotes-queretaro-puntolivo-galeria-canchas-tenis-1.jpg', caption: 'Cancha de Tenis' },
    ];
    const galleryGeneral = [
        { src: 'assets/images/concept/gallery-general/New_12_0000_final_HG.jpg', caption: 'Acceso' },
        { src: 'assets/images/concept/gallery-general/New_12_0001_final_HG.jpg', caption: 'General' },
        { src: 'assets/images/concept/gallery-general/New_12_0002_final_HG.jpg', caption: 'General' },
        { src: 'assets/images/concept/gallery-general/New_12_0004_final_HG.jpg', caption: 'General' },
        { src: 'assets/images/concept/gallery-general/New_12_0005_final_HG.jpg', caption: 'Casa Club' },
        { src: 'assets/images/concept/gallery-general/New_12_0006_final_HG.jpg', caption: 'General' },
        { src: 'assets/images/concept/gallery-general/New_12_0007_final_HG.jpg', caption: 'General' },
        { src: 'assets/images/concept/gallery-general/New_12_0008_final_HG.jpg', caption: 'Acceso' },
        { src: 'assets/images/concept/gallery-general/New_12_0009_final_HG.jpg', caption: 'Casa Club' },
    ];

    return (
        <>
            <Col className='pb-5 text-center'>
            </Col>
            <Col className='pb-5 px-4 text-center'>
                <Card>
                    <Card.Body>
                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} interval={null}>
                            <Carousel.Item className='concept-card'>
                                <div className="concept-card--content">
                                    <Row className='mt-4'>
                                        <Col>
                                            <img src="./assets/images/concept/proyecto-resindecial-lotes-queretaro-puntolivo-plan-masterplan.jpg" alt="" />
                                        </Col>
                                        <Col>
                                            <h3>Para que tus sueños respiren al aire libre</h3>
                                            <h4>EL PROYECTO</h4>
                                            <p>
                                                Descubre en PuntOlivo un lugar al que llamarás hogar. Encuentra un espacio donde reinan la paz y la tranquilidad, para compartir experiencias de vida únicas y divertirte con tu familia. Alcanza la cima, disfrutando de tus logros en PuntOlivo, el residencial pensado para quienes lo quieren todo.
                                            </p>
                                            <p>
                                                Vivir en PuntOlivo es refugiarte donde el tiempo se detiene y la armonía con la naturaleza se percibe en cada rincón. PuntOlivo es un espacio que perdurará en nuestras vidas, que las cambiará para siempre, donde compartirás recuerdos y experiencias irrepetibles, donde vivirás la vida que mereces.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <p>
                                                PuntOlivo es un desarrollo exclusivo formado por 12 condominios, de vivienda horizontal y vertical. Aquí nace una comunidad residencial diseñada para personas que valoran la vida en familia, la tranquilidad y la seguridad.
                                            </p>
                                            <p>
                                                Cuenta con seis macrolotes para vivienda vertical y 299 lotes residenciales desde 320 m², una Casa Club, un área con locales comerciales a la entrada del desarrollo y amplias áreas verdes distribuidas en parques, camellones, andadores y pista para correr.
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className='concept-card'>
                            <div className="concept-card--content">
                                    <Row className='mt-4'>
                                        <Col>
                                            <h3>Disfruta y comparte con tu familia</h3>
                                            <h4>AMENIDADES</h4>
                                            <p>
                                                Descubre todo lo que necesitas al alcance, disfruta del tiempo libre con tu familia en amenidades pensadas exclusivamente para ti, incluyendo una Casa Club y el lujo de la naturaleza rodeándote. Desarrolla tu potencial con equilibrio, dinamismo y confort en el lugar que tú mereces.
                                            </p>
                                        </Col>
                                        <Col>
                                            <Row className='mt-5 pt-4'>
                                                <Col md='6'>
                                                    <ul>
                                                        <li>					 
                                                            <span>01.</span>
                                                            <p>Alberca interior semi olímpica</p>
                                                        </li>
                                                        <li>					 
                                                            <span>02.</span>
                                                            <p>Alberca recreativa exterior</p>
                                                        </li>
                                                        <li>					 
                                                            <span>03.</span>
                                                            <p>Cancha multiusos</p>
                                                        </li>
                                                        <li>					 
                                                            <span>04.</span>
                                                            <p>Canchas de tenis y pádel tenis</p>
                                                        </li>
                                                        <li>					 
                                                            <span>05.</span>
                                                            <p>Vestidores para hombres y mujeres, equipados con sauna, vapor y sala de masajes</p>
                                                        </li>
                                                    </ul>
                                                </Col>
                                                <Col md='6'>
                                                    <ul>
                                                        <li>				
                                                            <span>06.</span>
                                                            <p>Gimnasio</p>
                                                        </li>
                                                        <li>
                                                            <span>07.</span>
                                                            <p>Salón de spinning</p>
                                                        </li>
                                                        <li>
                                                            <span>08.</span>
                                                            <p>Restaurante y terraza</p>
                                                        </li>
                                                        <li>
                                                            <span>09.</span>
                                                            <p>Salón de Jóvenes</p>
                                                        </li>
                                                        <li>
                                                            <span>10.</span>
                                                            <p>Salones de usos múltiples</p>
                                                        </li>
                                                        <li>
                                                            <span>11.</span>
                                                            <p>Áreas verdes</p>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Carousel
                                                indicators={false}
                                            >
                                                { galleryAmenities.map((image,key) => 
                                                    <Carousel.Item key={key}>
                                                        <img className="d-block w-100" src={image.src} alt={image.caption}
                                                        />
                                                        <Carousel.Caption>
                                                        <p>{image.caption}</p>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                )}
                                            </Carousel>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item className='concept-card'>
                                <div className="concept-card--content">
                                    <Row>
                                        <Col>
                                            <div className="video-wp">
                                                <iframe src="https://player.vimeo.com/video/408066824?&loop=1"></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Carousel
                                                indicators={false}
                                            >
                                                { galleryGeneral.map((image,key) => 
                                                    <Carousel.Item key={key}>
                                                        <img className="d-block w-100" src={image.src} alt={image.caption}
                                                        />
                                                        <Carousel.Caption>
                                                        <p>{image.caption}</p>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                )}
                                            </Carousel>
                                        </Col>
                                    </Row>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='pb-5 text-center'>
            </Col>
            <Col className='pb-5 text-center'>
            </Col>
        </>
    )
}
export default Concept;