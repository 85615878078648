import { Container, Row, } from 'react-bootstrap'
import Concept from "../Concept/Concept";
import Amenities from '../Amenities/Amenities';
import About from '../About/About';
import Simulator from '../Simulator/Simulator';

const SectionContent = ({ activeSection }) => {

    const getSection = (activeSection) => {
        switch(activeSection){
            case 'about': return <About />; break;
            case 'concept': return <Concept />; break;
            case 'amenities': return <Amenities />; break;
            case 'simulator': return <Simulator />; break;
            default: break;
        }
    }

    return (
        <Container fluid className={`position-absolute z-3 m-0 p-0 section ${activeSection}`} style={{ zIndex: 1 }}>
            <Row className='justify-content-center align-items-center h-100 m-0'>
                { getSection(activeSection) }
            </Row>
        </Container>
    )
}
export default SectionContent;