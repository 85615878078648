import { Container, Row, Col, Card, Carousel } from 'react-bootstrap'
import ReactPlayer from 'react-player'

const About = () => {
    return (
        <>
            <Col className='h-100 pb-5 text-center'>
                <ReactPlayer 
                    url='https://youtu.be/WN49fiLIQqQ&controls=0&showinfo=0&rel=0' 
                    width={'100%'} 
                    height={'75vh'}
                    playing={true}
                    loop={true}  />
            </Col>
        </>
    )
}
export default About;