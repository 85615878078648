import { useState } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap'
import './Simulator.scss'

const Simulator = () => {
    //const url = process.env.NODE_ENV==='development' ? `http://cnicolas.com/renderINC/punto-olivo/simulator/public/?embed=true` : `${window.location.href}../simulator/public?embed=true`;
    const url = `https://ppuntolivo.com/simulador/public/?embed=true`;
    //const url = `${window.location.href}simulator/public?embed=true`;
    return ( 
        <>
            <Col className='pb-5 text-center'>
            </Col>
            <Col lg={9} className='pb-5 text-start'>
                <div style={{background : '#414841'}}>
                    <iframe src={url} className='amenities--iframe w-100 d-block'></iframe>
                </div>
            </Col>
        </>
    )
}
export default Simulator;