import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Home from './pages/Home/Home';

function App() {
  return (
      <Home />
  );
}

export default App;
