import { Container, Row, Col, Button } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import SectionContent from '../../components/SectionContent/SectionContent';
import { useEffect, useState, useRef } from 'react';
import './Home.scss'

const Home = () => {
    const [activeSection, setActiveSection] = useState('');
    const [screensaverIsActive, setScreensaverIsActive] = useState(true);
    const timerRef = useRef(null);
    const screensaverTimeOut = 5000;

    // Screensaver timer
    const resetTimer = () => {
        clearTimeout(timerRef.current);
        if(activeSection==='')
            timerRef.current = setTimeout(() => {
                setScreensaverIsActive(true)
            },screensaverTimeOut)
    }
    useEffect(() => {
        if(!screensaverIsActive)
            resetTimer()
        else
            setActiveSection('')
    },[screensaverIsActive])
    useEffect(() => {
        resetTimer();
    },[activeSection])
    const handleActiveScreen = () => resetTimer()
    document.addEventListener('touchstart',resetTimer)

    // Section toggler
    const toggleSection = section => {
        if(section===activeSection)
            section='';
        setActiveSection(section)
    }
    const MainButton = ({section, label}) => {
        return (
            <Button variant='outline-secondary' className={`${activeSection==='' || (activeSection!=='' && activeSection===section)  ? 'home--btn-highlight' : ''}`} onClick={() => toggleSection(section)}>{activeSection===section ? 'Regresar' : label}</Button>
        )
    }

    useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    return (
        <>
            <Container fluid className={`home--video position-absolute h-100 m-0 p-0`} onClick={() => setScreensaverIsActive(false)}>
                <Row className='justify-content-center h-100 m-0 p-0 g-0'>
                    <Col>
                        <span className="home--video-cover"></span>
                        <ReactPlayer 
                            url='https://www.youtube.com/watch?v=zVz56Vd-qxQ&controls=0&showinfo=0&rel=0' 
                            width={'100%'} 
                            height={'100%'}
                            playing={true} 
                            muted={true}
                            loop={true} />
                    </Col>
                </Row>
            </Container>
            { !screensaverIsActive &&
                <Container fluid className='home--screen position-relative h-100 m-0 p-0' onClick={handleActiveScreen}>
                    <span className="home--deco home--deco--1" style={{ backgroundImage: `url(assets/images/img-ornament1-punto-olivo.svg)` }}></span>
                    <span className="home--deco home--deco--2" style={{ backgroundImage: `url(assets/images/img-ornament2-punto-olivo.svg)` }}></span>
                    <SectionContent activeSection={activeSection} />
                    <Container fluid className='home--buttons-wp position-relative h-100 m-0 p-0'>
                        <Row className='justify-content-center align-items-end h-100 m-0 p-0 g-0'>
                            <Col className='home--col text-center'>
                                <div className='home--company-logo'>
                                    <img src="assets/images/img-logo-copri.svg" alt='' />
                                </div>
                                <MainButton section='about' label='Grupo Copri' />
                            </Col>
                            <Col className='home--col text-center'>
                                <MainButton section='concept' label='Concepto' />
                            </Col>
                            <Col className='home--col text-center'>
                                <MainButton section='amenities' label='Amenidades' />
                            </Col>
                            <Col className='home--col text-center'>
                                <div className='home--main-logo'>
                                    <img src="assets/images/img-logo-punto-olivo.svg" />
                                </div>
                                <MainButton section='simulator' label='Simulador' />
                            </Col>
                        </Row>
                    </Container>
            </Container>
            }
        </>
    )
}
export default Home;