import { Container, Row, Col, Button } from 'react-bootstrap'
import './Amenities.scss'

const Amenities = () => {
    return (
        <>
            <Col className='pb-5 text-center'>
            </Col>
            <Col lg={9} className='pb-5 text-start'>
                <iframe src="https://jxnglobal.com/puntolivo/" className='amenities--iframe w-100'></iframe>
            </Col>
        </>
    )
}
export default Amenities;